import { post } from '../../api/api'
import { FORGOT_PASSWORD, GENERATE_TOKEN, FORGOT_PASSWORD_VERIFY_CODE, CHANGE_PASSWORD, BASE_URL_JWT, LOGG_INFO} from '../../api/baseURL'
import { CLIENT_ID } from '../../utils/config'
import { isEmailValid } from '../../utils/validators'
import { checkIsMobile, getUniqueDeviceId, setLocalStorage } from '../../utils/utils'
import { getUserInfoDb } from '../../offlineManager/userAuthOfflineManager'

const setSessionData = (res) =>{
    setLocalStorage('token', res.data.tokenGeneration.accessToken);
    setLocalStorage('refreshToken', res.data.tokenGeneration.refreshToken);
    setLocalStorage('expiration', res.data.tokenGeneration.expiration)
}

export const loginAction = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let email = '';
        let employeeCode = "";
        let userDeviceId = getUniqueDeviceId();
        if(isEmailValid('', payload.formData.username)){
            email = payload.formData.username
        }
        else {
            employeeCode = payload.formData.username
        }
        await getUserInfoDb().then((result) => {
            if (result && result.length > 0) {
                const { deviceId } = result[0];
                userDeviceId = deviceId;
            }
        })
        .catch((err) => {
            console.log('device id not found, using default', err);
        })
        let formdata = {
            "email": email.trim(),
            "password": payload.formData.password.trim(),
            "employeeCode": employeeCode.trim(),
            "deviceID": userDeviceId,
            "geoLocation": payload.formData.geoLocation,
            "lastLoginDate": new Date().toISOString(),
            "isMobileDevice": checkIsMobile(),
            "clientID": CLIENT_ID
        }
        let result = {
            'success': true
        }
        try {
            const res = await post(GENERATE_TOKEN, formdata)
            if(res.status === 200){
                setSessionData(res)
                // setLocalStorage('deviceId', res.data.deviceId)
                setLocalStorage('roleId', res.data.roleID)
                let userProfileInfo = {
                    userId: res.data.id,
                    email: res.data.email,
                    roleId : res.data.roleID,
                    rolename: res.data.rolename,
                    userType: res.data.userType,
                    firstName: res.data.firstname ? res.data.firstname : '',
                    lastName: res.data.lastname ? res.data.lastname : '',
                    employeeCode: res.data.employeeCode ? res.data.employeeCode : '',
                    lastLoginDatetime: res.data.lastLoginDatetime ? res.data.lastLoginDatetime : '',
                    agentCode: res.data.agentCode === 'NULL' ? res.data.employeeCode : res.data.agentCode,
                    channelid: res.data.channelid ? res.data.channelid : 1,
                    channel: res.data.channel ? res.data.channel : "BANCA",
                    deviceId: res.data.deviceId,
                    isFirstLogin :res.data.isFirstLogin,
                    reportingMgrfirstname:res.data.reportingMgrfirstname,
                    reportingMgrlastname:res.data.reportingMgrlastname,
                    edmChannel:res.data.edmChannel,
                    edmChannelID:res.data.edmChannelID
                }
                dispatch({
                    type:"isSettingUpActivate",
                    payload:true
                })
                setLocalStorage('permissions', JSON.stringify(res.data.roleMapping))
                dispatch({
                    type: 'PROFILE_INFO',
                    payload: userProfileInfo
                })
            }
            else {
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: res.data})
                result['success'] = false
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            result['userData'] = res.data
            resolve(result)
        } catch (err) {
            console.log('in login error ', err)
            dispatch({type:'DEACTIVATE_LOADER', payload: false});
            if(err && err.response.data && err.response.data.title){
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: err.response.data.title})
                if(err.response.status == 400 && err.response.data.responsestatus == false &&  err.response.data.title  === "You seem to be logged in to another device"){
                    dispatch({ type:"isDeviceReset", payload:true })
                    dispatch({type:"userIdForDeviceReset",payload:err.response.data.user.userID})
                } else if(err.response.status == 400 && err.response.data.responsestatus == false &&  err.response.data.title  === "User not found."){
                    dispatch({ type:"isUserFound", payload:true })
                }
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
            }
            resolve({success: false})
        }
    })
}
export const requestForgotPassword = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let formdata = {
            "email": payload.email,
        }
        let result = {
            'success': true
        }
        try {
            const res = await post(FORGOT_PASSWORD, formdata)
            if(res.status === 200 && res.data.message.updatePassword){
                dispatch({type:'FORGOT_PASSWORD_STEP',payload: 2})
                dispatch({type:'ACTIVATE_SUCCESS_MESSAGE_BAR',payload: "An email with verification code, has been sent to your email ID."})
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: res.data.message.errorMessage})
                result['success'] = false
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            resolve(result)
        } catch (err) {
            if(err && err.data && err.data.title){
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: err.data.title})
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            resolve({success: false})
        }
    })
}

export const changePasswordWithCodeAction = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let result = {"success": true}
        try {
            const res = await post(FORGOT_PASSWORD_VERIFY_CODE + "?verifyCode="+payload.formData.verifyCode+"&newPassword="+payload.formData.newPassword, {})
            if(res.status === 200 && res.data.changePassword){
                dispatch({type:'FORGOT_PASSWORD_STEP',payload: 1})
                dispatch({type:'ACTIVATE_SUCCESS_MESSAGE_BAR',payload: res.data.message})
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: res.data.message})
                result['success'] = false
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            resolve(result)
        } catch (err) {
            if(err && err.data && err.data.title){
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: err.data.title})
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            resolve({success: false})
        }
    })
}

export const changePasswordAction = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let result = {"success": true}
        try {
            const res = await post(CHANGE_PASSWORD + "?email="+payload.formData.email+"&oldPassword="+payload.formData.oldPassword+"&newPassword="+payload.formData.newPassword, {
                "email": payload.formData.email,
                "oldPassword": payload.formData.oldPassword,
                "newPassword": payload.formData.newPassword
            })
            if(res.status === 200 && res.data === true){
                dispatch({type:'ACTIVATE_SUCCESS_MESSAGE_BAR',payload: "Your password has been changed successfully."})
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: res.data })
                result['success'] = false
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            resolve(result)
        } catch (err) {
            if(err && err.data && err.data.title){
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: err.data.title})
            }
            else{
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: "Something went wrong! please try again later."})
            }
            dispatch({type:'DEACTIVATE_LOADER', payload:false});
            resolve({success: false})
        }
    })
}

export const setForgotPasswordStep = (payload)=>({
    type:'FORGOT_PASSWORD_STEP', payload:payload
})
export const logOutAction = (payload) => ({type:'USER_LOGGED_OUT', payload: payload });
export const ssoAuthValidateUserData = (payload) => (dispatch, getState) =>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res= await post(`${BASE_URL_JWT}${'/api/validatetoken?'}${'authToken='}${payload.authToken}${'&email='}${payload.email}`)
            let result = {
                'success': true
            }
            if(res.status === 200 && res.data.channelid){
                setSessionData(res)
                // setLocalStorage('deviceId', res.data.deviceId)
                setLocalStorage('roleId', res.data.roleID)
                let userProfileInfo = {
                    userId: res.data.id,
                    email: res.data.email,
                    roleId : res.data.roleID,
                    rolename: res.data.rolename,
                    userType: res.data.userType,
                    firstName: res.data.firstname ? res.data.firstname : 'Abhilash',
                    lastName: res.data.lastname ? res.data.lastname : 'Krishnaswamy',
                    employeeCode: res.data.employeeCode ? res.data.employeeCode : '',
                    lastLoginDatetime: res.data.lastLoginDatetime ? res.data.lastLoginDatetime : '',
                    agentCode: res.data.agentCode === 'NULL' ? res.data.employeeCode : res.data.agentCode,
                    channelid: res.data.channelid ? res.data.channelid : 1,
                    deviceId: res.data.deviceId,
                    isFirstLogin :res.data.isFirstLogin,
                    reportingMgrfirstname:res.data.reportingMgrfirstname,
                    reportingMgrlastname:res.data.reportingMgrlastname
                }
                setLocalStorage('permissions', JSON.stringify(res.data.roleMapping))
                dispatch({
                    type: 'PROFILE_INFO',
                    payload: userProfileInfo
                })
                result['userData'] = res.data
                resolve(result)
            } else {
                dispatch({type:'ACTIVATE_ERROR_MESSAGE_BAR',payload: 'error'})
                result['success'] = false
                dispatch({type:'DEACTIVATE_LOADER', payload:false});
                result['userData'] = res.data
                resolve(result)
            }
        } catch (err){
            reject(err)
        }
    })
}
export const loggInfo = (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        await post(LOGG_INFO, payload).then((response) => {
            console.log("LOGG_INFO success", response);
            console.log("LOGG_INFO payload", payload);
            resolve(response);
        });
      } catch (e) {
        reject(e);
        console.log("LOGG_INFO error", e);
      }
    });
};