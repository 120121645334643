import React from 'react'
import { Route, Redirect } from "react-router-dom";
import login from '../pages/prelogin/login/login';
import MessageBar from '../components/messageBar/messageBar';
import Loader from '../components/loader/loader';

const AuthLayout = () =>{
    return (
      <div className='auth-layout'>
        <div className='auth-container content'>
            <Route path="/auth/login" exact component={login} />
            <Redirect from="/auth" to="/auth/login" exact />
            <Route />
        </div>
        <div className='auth-container footer'>
            <MessageBar ></MessageBar>
            <Loader></Loader>
        </div>
      </div>
    );
}

export default AuthLayout
