import * as CryptoJS from 'crypto-js';
import { format } from 'date-fns'
import moment from 'moment';
//import fs from 'fs';

const encryptSecretKey = "b14ca5898a4e4133bbce2ea2315a1916";
export const dateFormat = 'yyyy-MM-dd';
export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss"
export const dateTimeFormatWithT = "YYYY-MM-DDTHH:mm:ss"

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const downloadFilesAdmin=(response ,filename)=>{
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}${moment().format('yyyy/MM/DD/HHmmss')}.csv`);
    document.body.appendChild(link);
    link.click();
    // OR you can save/write file locally.
    //  fs.writeFileSync(`${filename}${moment().format('yyyy/MM/DD/HHmmss')}.csv`, response.data);
  }

// function to encrypt data to send to API
export const encryptApiData = (msg) => {
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(encryptSecretKey, salt, {
        keySize: keySize / 32,
        iterations: 100
    });

    var iv = CryptoJS.lib.WordArray.random(128 / 8);

    var encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });

    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));

    return result;
}

// function to decrypt data from API
export const decryptApiData = (ciphertext) => {

    var key = CryptoJS.enc.Utf8.parse(encryptSecretKey);
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);

    var decrypted = CryptoJS.AES.decrypt(ciphertext, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

// function to encrypt the localstorage
export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), encryptSecretKey).toString();

}

export const userData = () => {
    const userDetails = JSON.parse(localStorage.getItem("persist:root"));
    return JSON.parse(userDetails.userReducer).profileInfo.userId;
}

// function to decrypt the localstorage
export const decryptData = (data) => {
    if (!data) {
        return ''
    }
    const bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// function to set the data into session storage
export const setSession = (key, value) => {
    return localStorage.setItem(key, encryptData(value));
}

// function to get the data from session storage
export const getSession = (key) => {
    const encryptedData = localStorage.getItem(key)
    return decryptData(encryptedData);
}

// function to set the data into session storage
export const setLocalStorage = (key, value) => {
    return localStorage.setItem(key, encryptData(value));
}

// function to get the data from session storage
export const getLocalStorage = (key) => {
    const encryptedData = localStorage.getItem(key);
    return decryptData(encryptedData);
}

// function to format date
// input format : '2021-10-25T10:13:05'
// output format to pass:
// dd : 25
// MM/M : 10
// yyyy : 2021
// yy : 21
//  e.g : getFormattedDate('2021-10-25T10:13:05', 'dd-MM-yy hh:mm:ss')
//  output : 25-10-21 24:0:0

export const getFormattedDate = (inputDate, datePattern = 'dd-MM-yy k:m:s') => {
    const dateTimeArr = inputDate.split('T');
    const dateArr = dateTimeArr[0].split('-');
    const timeArr = dateTimeArr[1].split(':');
    return format(new Date(dateArr[0], parseInt(dateArr[1]) - 1, dateArr[2], timeArr[0], timeArr[1], timeArr[2].replace('Z', '')), datePattern)
}

export const checkIsMobile = () => {
//     return navigator && navigator.userAgentData && navigator.userAgentData.mobile ? navigator.userAgentData.mobile : false;

    let isMobileOrTablet = false;
    const userAgent = navigator.userAgent;
    const mobileOrTabletRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
     isMobileOrTablet = mobileOrTabletRegex.test(userAgent);
    return isMobileOrTablet
}

export const isUserSame = (assigneto, userId) => {
    return parseInt(assigneto) === parseInt(userId)
}


export const getDateDuration = (startDateTime, endDateTime, type = "HOUR") => {
    if (!startDateTime) {
        return ''
    }
    const startTime = moment(startDateTime)
    const endTime = moment(endDateTime)
    var duration = moment.duration(endTime.diff(startTime));
    var minutes = duration.asMinutes();
    let result = minutes > 59 ? (minutes - (parseInt(minutes / 60) * 60)) : minutes
    result = result > 0 ? `${result.toString()} Mins` : ""
    if (type === "HOUR") {
        result = parseInt(minutes / 60)
        result = result > 0 ? `${result.toString()} Hour(s), ` : ""
    }
    return result
}


export const getDateAfterDays = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}
export const getDateHoursMinutes = (startDateTime, endDateTime, type = "HOUR") => {
    if (!startDateTime) {
        return ''
    }
    const startTime = moment(startDateTime)
    const endTime = moment(endDateTime)
    var duration = moment.duration(endTime.diff(startTime));
    var minutes = duration.asMinutes();
    let result = minutes > 59 ? (minutes - (parseInt(minutes / 60) * 60)) : minutes

    var hourse = minutes / 60;
    var rhours = Math.floor(hourse);

    var minute = (hourse - rhours) * 60;
    var rminutes = Math.round(minute);
    result = rhours ? (rhours + " hour and " + rminutes + " minutes.") : (rminutes + " minutes.");
    return result;
}

export const datedata = (option) => {
    const dateformat = 'YYYY-MM-DD';
    switch (option) {
        case 'thismonth':
            const startOfMonth = moment().startOf('month').format(dateformat);
            const endOfMonth = moment().endOf('month').format(dateformat);
            return `${startOfMonth},${endOfMonth}`;
        case "7days":
            const daybefor = moment().subtract(6, "days").format(dateformat);
            const today = moment().subtract(1, 'days').format(dateformat);
            return `${daybefor},${today}`;
        case "30days":
            const befornoofday = moment().subtract(29, "days").format(dateformat);
            const todaydate = moment().format(dateformat);
            return `${befornoofday},${todaydate}`;
        case "last30days":
            const last30day = moment().subtract(30, "days").format(dateformat);
            const lastdate = moment().subtract(1, 'days').format(dateformat);
            return `${last30day},${lastdate}`;
        case 'lastmonth':
            const lastmonthfirstday = moment().subtract(1, 'month').startOf('month').format(dateformat);
            const lastmonthlastday = moment().subtract(1, 'month').endOf('month').format(dateformat);
            return `${lastmonthfirstday},${lastmonthlastday}`;
        case 'thisweek':
            const startDate = moment().startOf('week').format(dateformat);
            const endDate = moment().endOf('week').format(dateformat);
            return `${startDate},${endDate}`;
        case "Today":
            return `${moment(new Date()).format(dateformat)},${moment(new Date()).format(dateformat)}`
        case "Yesterday":
            return `${moment().subtract(1, 'days').format(dateformat)},${moment().subtract(1, 'days').format(dateformat)}`
        case "15days":
            const last15day = moment().subtract(15, "days").format(dateformat);
            const last1day = moment().subtract(1, 'days').format(dateformat);
            return `${last15day},${last1day}`;
        default:
            return `${null},${null}`
    }
}
export const getDropdownDataBranch = (optionsArr, key, label, value) => {
    optionsArr.shift();
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
        arr = optionsArr?.map((option) => {
            return { key: option[key], label: `${option[key]} - ${option[label]}`, value: option[value] }
        })
    }
    arr.unshift({ "key": "select", "label": "select", "value": "" })
    return arr;
}
export const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
        arr = optionsArr?.map((option) => {
            return { key: option[key], label: `${option[key]} - ${option[label]}`, value: option[value] }
        })
    }
    arr.unshift({ "key": "select", "label": "select", "value": "" })
    return arr;
}

export const getDropdownDataAdmin = (data, dropDownConfig, channelID = '') => {
    let options = [
        { "key": "Select", "label": "Select", "value": "" }
      ]
     
      for (const option of data) {
      
        const optionItem = {
            "key": option[dropDownConfig.CODE],
            "label": option[dropDownConfig.LABEL],
            "value": option[dropDownConfig.KEY] ? option[dropDownConfig.KEY].toString() : ''
        }
        options.push(optionItem)
    }
      return options
}

//GET MOBILE OS
export const getMobileOS = () => {

    const ua = navigator.userAgent

    if (/android/i.test(ua)) {
        return "Android"
    }

    else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return "iOS"
    }

    return "Other"
}
export const minusMinuteFromDate = (date) => {
    let newDate = moment(date).subtract(1, "minutes");
    return newDate.format('YYYY-MM-DDTHH:mm');

}
export const addMinuteToDate = (date) => {
    let newDate = moment(date).add(1, "minutes");
    return newDate.format('YYYY-MM-DDTHH:mm');

}

export const getBuildDate = (epoch) => {
    const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
    return buildDate;
};
export const getUniqueDeviceId = () => {
    // eslint-disable-next-line no-undef
    var uuid = new DeviceUUID().get();
    return uuid;
}
export const getEnvName = () => {
    const urlLocation = window.location.origin;
    if (urlLocation.indexOf('dev') !== -1 || urlLocation.indexOf('localhost') !== -1) {
        return '(D)';
    } else if (urlLocation.indexOf('qa') !== -1) {
        return '(Q)';
    } else if (urlLocation.indexOf('uat') !== -1) {
        return '(U)';
    } else {
        return '(P)';
    }
}

export const goToUploadBill = (url) => {
    window.open(url, "_blank")
}

export const DownloadFiles=(response)=>{
    const url = URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `BDMChecklist${moment().format('yyyy/MM/DD/HHmmss')}.csv`);
    document.body.appendChild(link);
    link.click();
    // OR you can save/write file locally.
    // fs.writeFileSync("test.csv", response);

  }
