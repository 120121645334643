import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { getUserHome, isAuthenticated, isAuthorized } from '../auth';
import useOnlineStatus from "../hooks/useOnlineStatus";

export const PublicRoute = ({ component: Component, restricted, path, ...rest }) => {
    const isOnline = useOnlineStatus();
    return (
      <Route {...rest} render={props => {
        if (isAuthenticated() && path === '/auth') {
          // already logged in so redirect to home page
          if (isOnline) {
            return <Redirect to={{ pathname: getUserHome()}} />
          }
          return <Redirect to={{ pathname: getUserHome()}} />
        }
        return <Component {...props} />
    }} />
    );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page.!
      <Route {...rest} render={props => {
        if (!isAuthenticated()) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/'}} />
        }

        return <Component {...props} />
    }} />
    );
  };

  export const AuthorizedRoute = ({ component: Component, permission, ...rest }) => {
    return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page.!
      <Route {...rest} render={props => {
        // check if route is restricted by role
        if (!isAuthorized(permission)) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: getUserHome()}} />
        }
        // authorised so return component
        return <Component {...props} />
    }} />
    );
  };
